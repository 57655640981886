<template>
  <div class="select-outer">
    <van-nav-bar
      safe-area-inset-top
      title="券品"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="filter-top">
   
      <div class="search-block">
        <van-search
          v-model="valueSearch"
          placeholder="请输入券名称"
          background="#FFF"
          shape="round"
          class="search"
          @search="onSearch"
        />
      </div>
      <div class="top-select-drop">
        <van-dropdown-menu active-color="#006c50">
          <van-dropdown-item
            v-model="dropdownValue"
            :options="dropdownList"
            @change="dropdownChange"
          />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="subfilter filter-top">
         操作的下标{{optionCarIndex}}```申请类型{{selectType}}`
      <div class="type-btns" v-if="selectType != '4' && selectType != '5'">
        <span
          :class="{ 'btn-comm': true, 'cur-btn': btnCur == 0 }"
          @click="changeBtb(0)"
          >纸质</span
        >
        <span
          :class="{ 'btn-comm': true, 'cur-btn': btnCur == 1 }"
          @click="changeBtb(1)"
          >电子</span
        >
      </div>
      <template v-if="selectType == '5'">
        <div class="top-select">
          <el-select v-model="dropStatusValue">
            <el-option
              v-for="item in dropStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="top-select">
          <el-select v-model="dropTicketNumValue">
            <el-option
              v-for="item in dropTicketNumOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <span
        class="height-search"
        @click="showHeightSearch"
        v-if="selectType == '5'"
        >高级搜索</span
      >
    </div>
    <div class="con-outer">
      <!-- 券延期item带多选，单独拎出来selectType是5是延期 -->
      <template v-if="selectType != '5'">
        <template v-for="(item, index) in rightList">
          <div class="con-item" :key="index">
            <div class="item-title" @click="goEditTick(item.id)">
              {{ item.brandName }}
            </div>
            <!-- 单体券 -->
            <template v-if="selectType == '1'">
              <div class="item-line-between">
                <span class="left"> 有效期：{{item.timeEnd}}{{item.timeType=='0'?"天":""}} </span>
                <div class="right">
                  <van-checkbox-group
                    v-model="result"
                    checked-color="#006c50"
                    direction="horizontal"
                  >
                    <van-checkbox name="a" label-position="left"
                      >纸质</van-checkbox
                    >
                    <van-checkbox name="b" label-position="left"
                      >电子</van-checkbox
                    >
                  </van-checkbox-group>
                </div>
              </div>
              <!-- <div class="item-line">
                <span @click="goEditTick(item.id)">库存：4张</span>
                <van-stepper
                  step="1"
                  min="0"
                  integer
                  :name="item.id"
                  v-model.number="item.num"
                  @change="stepChange"
                />
              </div> -->
            </template>
            <!-- 销售礼券 -->
            <template v-else-if="selectType == '2'">
              <div class="item-line-clum" @click="goEditTick(item.id)">
                <span class="one">有效期：2024-10-10</span>
                <span class="two">面额：¥299.99</span>
                <div class="three">
                  <div class="circle">+</div>
                </div>
              </div>
            </template>
            <!-- 免费礼券申请 -->
            <template v-else-if="selectType == '3'">
              <div class="item-line-con" @click="goEditTick(item.id)">
                <span>效期类型：有效天数</span>
              </div>
              <div class="item-line">
                <span @click="goEditTick(item.id)">有效期：30天</span>
                <van-stepper
                  step="1"
                  min="0"
                  integer
                  :name="item.id"
                  v-model.number="item.num"
                  @change="stepChange"
                />
              </div>
            </template>
            <!-- 销售折扣申请 -->
            <template v-else-if="selectType == '4'">
              <div class="item-line">
                <span @click="goEditTick(item.id)">面额：¥299.99</span>
                <van-stepper
                  step="1"
                  min="0"
                  integer
                  :name="item.id"
                  v-model.number="item.num"
                  @change="stepChange"
                />
              </div>
              <div class="item-line-option" @click="goEditTick(item.id)">
                <span class="price">申请折扣:</span>
                <span>50%</span>
                <span>请填写 ></span>
              </div>
              <div class="item-line-option" @click="goEditTick(item.id)">
                <span class="price">申请单价:</span>
                <span>10</span>
                <span>请填写 ></span>
              </div>
              <div class="item-line-option" @click="goEditTick(item.id)">
                <span class="price">申请总价:</span>
                <span>10</span>
                <span>请填写 ></span>
              </div>
            </template>
            <!-- 券品延期申请 -->
            <!-- <template v-else-if="selectType == '5'">
                  <div
                    class="item-line-con btween"
                    @click="goEditTick(item.id)"
                  >
                    <span>券类：月饼券</span>
                    <span>券介质：纸质券</span>
                  </div>
                  <div
                    class="item-line-con btween"
                    @click="goEditTick(item.id)"
                  >
                    <span>券用途：免费</span>
                    <span>有效期：2024-03-10</span>
                  </div>
                  <div
                    class="item-line-con btween"
                    @click="goEditTick(item.id)"
                  >
                    <span>是否过期：已过期</span>
                    <span>状态：发放</span>
                  </div>
                </template> -->
          </div>
        </template>
      </template>
      <template v-else>
        <!-- 券延期 -->
        <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
          <template v-for="(item, index) in rightList">
            <div class="delay-item-outer" :key="index">
              <el-checkbox :label="item" :key="item.id"></el-checkbox>
              <div class="con-item">
                <div class="item-title" @click="goEditTick(item)">
                  {{ item.title }}
                </div>
                <div class="item-line-con btween" @click="goEditTick(item.id)">
                  <div class="t-and-txt">
                    <span class="color-t">券类：</span>
                    <span>月饼券</span>
                  </div>
                </div>
                <div class="item-line-con btween" @click="goEditTick(item.id)">
                  <div class="t-and-txt">
                    <span class="color-t">券介质：</span>
                    <span>纸质券</span>
                  </div>
                </div>
                <div class="item-line-con btween" @click="goEditTick(item.id)">
                  <div class="t-and-txt">
                    <span class="color-t">券用途：</span>
                    <span>免费</span>
                  </div>
                </div>
                <div class="item-line-con btween" @click="goEditTick(item.id)">
                  <div class="t-and-txt">
                    <span class="color-t">有效期：</span>
                    <span>2024-03-10</span>
                  </div>
                </div>
                <div class="item-line-con btween" @click="goEditTick(item.id)">
                  <div class="t-and-txt">
                    <span class="color-t">是否过期：</span>
                    <span>已过期</span>
                  </div>
                </div>
                <div class="item-line-con btween" @click="goEditTick(item.id)">
                  <div class="t-and-txt">
                    <span class="color-t">状态：</span>
                    <span>发放</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-checkbox-group>
      </template>
      <!-- <van-tree-select
        height="100%"
        :items="items"
        :main-active-index.sync="active"
      >
        <template #content>
         
        </template>
      </van-tree-select> -->
    </div>

    <div class="submit-btn-outer">
      <div class="submit-nums">
        <span>合计：</span>
        <span class="num">{{ selectTickCount }}</span>
        <span>张</span>
      </div>
      <!-- 延期的要展示延期按钮，其他叫已选好了 -->
      <template v-if="selectType != '5'">
        <van-button round type="info" color="#006C50" @click="submitSelect"
          >选好了</van-button
        >
      </template>
      <template>
        <van-button round type="info" color="#006C50" @click="submitDelay"
          >延期</van-button
        >
      </template>
    </div>

    <van-popup
      v-model="showSku"
      closeable
      round
      position="bottom"
      :style="{ 'max-height': '90%' }"
    >
      <div class="popup-sku-con-outer">
        <div class="pop-title-con">
          <span>券状态</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in poptypelist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveTypeCrrent,
              }"
              :key="index"
              @click="changeCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-title-con">
          <span>券类</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popTickTypelist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveTicketType,
              }"
              :key="index"
              @click="changeTickCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <!-- bianma -->
        <div class="input-outer">
          <span class="input-pop-title">开始编码：</span>
          <div class="edit-input">
            <el-input
              v-model="popStartNum"
              placeholder="请填写"
              clearable
              type="number"
            ></el-input>
          </div>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">结束编码：</span>
          <div class="edit-input">
            <el-input
              v-model="popEndNum"
              placeholder="请填写"
              clearable
              type="number"
            ></el-input>
          </div>
        </div>
        <div class="pop-title-con">
          <span>券介质</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popDielectriclist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveDielectric,
              }"
              :key="index"
              @click="changeDlCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-title-con">
          <span>券用途</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popUselist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveUse,
              }"
              :key="index"
              @click="changeUseCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-title-con">
          <span>时间类型</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popTimeTypelist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveTimeType,
              }"
              :key="index"
              @click="changeTimeTypeCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-date-line-outer">
          <span class="title">开始与结束时间：</span>
          <div class="line-right" @click="popRightChange">
            <span>{{ rightStartTime }}</span>
            <span
              >{{ rightStartTime ? "更改" : "请选择"
              }}<i class="el-icon-arrow-down"></i
            ></span>
          </div>
        </div>
        <div class="pop-btns">
          <van-button round plain>重置</van-button>
          <van-button type="primary" round @click="popBottomBtnSearch"
            >搜索</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 延期 -->
    <van-popup
      v-model="showDelayPop"
      closeable
      round
      position="bottom"
      :style="{ 'max-height': '90%' }"
    >
      <div class="popup-sku-con-outer delay-outer">
        <div class="pop-title-con">
          <span>延期</span>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">延期天数：</span>
          <div class="edit-input">
            <el-input
              v-model="popDelayDays"
              placeholder="请填写"
              clearable
              type="number"
            ></el-input>
            <span>天</span>
          </div>
        </div>

        <div class="pop-date-line-outer">
          <span class="title">延期至：</span>
          <div class="line-right" @click="popDelayChange">
            <span>{{ delayToTime }}</span>
            <span
              >{{ delayToTime ? "更改" : "请选择"
              }}<i class="el-icon-arrow-down"></i
            ></span>
          </div>
        </div>
        <div class="pop-btns">
          <!-- <van-button round plain>重置</van-button> -->
          <div class="count">
            <span>合计：</span>
            <span class="num">32</span>
            <span>张</span>
          </div>
          <van-button type="primary" round @click="popBottomBtnSearch"
            >确认</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-calendar
      v-model="showPopStartDate"
      @confirm="btnPopStartDate"
      :min-date="minDate"
      :max-date="maxDate"
      type="range"
      color="#006C50"
    />
    <van-calendar
      v-model="showPopDelay"
      @confirm="btnPopDelayDate"
      color="#006C50"
    />
    <!-- <van-calendar
      v-model="showPopDelay"
      @confirm="btnPopDelayDate"
      :max-date="maxDate"
      :min-date="minDate"
      color="#006C50"
    /> -->
    <!-- <van-number-keyboard safe-area-inset-bottom /> -->
  </div>
</template>

<script>
import {  Toast } from "vant";
import { mapGetters } from "vuex";
import { getAllCategory, getBrandByCategoryId } from "@/api/process";
import moment from "moment";
export default {
  data() {
    return {
      valueSearch: "",
      btnCur: 1,
      active: 0,
      value1: 0,
      showSku: false,
      showPopDelay: false,
      popDelayDays: "",
      showDelayPop: false,
      popDelayDays: "",
      dropdownValue: "",
      result: [],
      optionCarIndex:"",
      dropStatusValue: "1",
      dropTicketNumValue: "1",
      checkedList: [],
      delayToTime: "",
      maxDate: moment().toDate(),
      minDate: moment().subtract(30, "days").toDate(),
      showPopStartDate: false,
      inputPrice: 0,
      popStartNum: "",
      popEndNum: "",
      rightStartTime: "",
      popActiveTypeCrrent: 1,
      dropStatusOptions: [
        {
          value: "1",
          label: "已过期",
        },
        {
          value: "2",
          label: "已使用",
        },
        {
          value: "3",
          label: "已延期",
        },
      ],
      dropTicketNumOptions: [
        {
          value: "1",
          label: "券号",
        },
        {
          value: "2",
          label: "嘻嘻嘻",
        },
        {
          value: "3",
          label: "哈哈哈",
        },
      ],
      poptypelist: [
        { text: "已过期", value: 1 },
        { text: "未过期", value: 2 },
      ],
      popActiveTimeType: 1,
      popTimeTypelist: [
        { text: "销售日期", value: 1 },
        { text: "发放日期", value: 2 },
        { text: "有效期", value: 3 },
      ],
      popActiveUse: 1,
      popUselist: [
        { text: "销售", value: 1 },
        { text: "工具", value: 2 },
        { text: "免费", value: 3 },
      ],
      popActiveDielectric: 1,
      popDielectriclist: [
        { text: "电子券", value: 1 },
        { text: "纸质券", value: 2 },
      ],
      popActiveTicketType: 1,
      popTickTypelist: [
        { text: "分类一", value: 1 },
        { text: "分类二", value: 2 },
        { text: "分类三", value: 3 },
        { text: "分类4", value: 4 },
        { text: "分类5", value: 5 },
        { text: "分类6", value: 6 },
        { text: "分类7", value: 8 },
        { text: "分类9", value: 9 },
        { text: "分类10", value: 10 },
      ],
      valueNum: 1,
      selectType: "", //1.调拨单申请2.销售礼券申请 3免费礼券申请4.销售折扣申请5券品延期申请
      rightList: [
        {
          id: "01",
          title: "中秋月饼",
          num: 0,
          price: 0,
        },
        {
          id: "02",
          title: "usi对双对数",
          num: 0,
          price: 0,
        },
        {
          id: "03",
          title: "水电费是的",
          num: 0,
          price: 0,
        },
        {
          id: "04",
          title: "二二",
          num: 0,
          price: 0,
        },
        {
          id: "05",
          title: "人人",
          num: 0,
          price: 0,
        },
        {
          id: "06",
          title: "中润融通融让他让他秋月饼",
          num: 0,
          price: 0,
        },
        {
          id: "07",
          title: "中秋月啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊的饼",
          num: 0,
          price: 0,
        },
        {
          id: "08",
          title: "中秋啥的月饼",
          num: 0,
          price: 0,
        },
        {
          id: "09",
          title: "啥的二",
          num: 0,
          price: 0,
        },
        {
          id: "10",
          title: "哈哈哈",
          num: 0,
          price: 0,
        },
        {
          id: "11",
          title: "发发发",
          num: 0,
          price: 0,
        },
      ],
      dropdownList: [],
      goods: {
        // 默认商品 sku 缩略图
        picture: "https://img01.yzcdn.cn/1.jpg",
      },
    };
  },
  methods: {
    dropdownChange(v) {
      //console.log("下拉选了" + v);
      this.dropdownValue = v;
      this.getBrandByCategoryList()
    },
    getAllCategoryList() {
      getAllCategory()
        .then((rsp) => {
          //console.log("~~~~" + JSON.stringify(rsp.data));
          this.dropdownList = rsp.data.data.map((item) => {
            return {
              text: item.categoryName,
              value: item.id,
            };
          });
          this.dropdownValue = this.dropdownList[0].value;
         this.getBrandByCategoryList()
          // Toast.success("提交成功");
          // this.submitLoading = false;
          // this.$router.push("/workspace/submit");
        })
        .catch((err) => {
          Toast.fail("获取券类失败");
        });
    },
    getBrandByCategoryList() {
      //console.log("获取券列表"+this.dropdownValue+"``"+this.valueSearch)
      getBrandByCategoryId(this.dropdownValue,this.valueSearch)
        .then((rsp) => {
            this.rightList=rsp.data.data
        
        })
        .catch((err) => {
          Toast.fail("获取券类失败");
        });
    },
    showHeightSearch() {
      this.showSku = true;
    },
    popDelayChange() {
      this.showPopDelay = true;
    },
    submitDelay() {
      this.showDelayPop = true;
    },
    handleCheckedChange(selectArr) {
      //console.log("多选了" + JSON.stringify(selectArr));
      //多选直接可以存购物车里
      // 更新 Vuex 数据

      this.$store.commit("clearTickCartList");

      this.$store.commit("setTickCarList", selectArr);
    },
    popBottomBtnSearch() {
      this.showSku = false;
    },
    popRightChange() {
      this.showPopStartDate = true;
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    // onConfirm(date) {
    //   const [start, end] = date;
    //   this.show = false;
    //   this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    // },
    btnPopStartDate(date) {
      const [start, end] = date;
      this.showPopStartDate = false;
      this.rightStartTime = `${this.formatDate(start)} - ${this.formatDate(
        end
      )}`;
    },
    btnPopDelayDate(date) {
      //  this.delayToTime=date
      this.showPopDelay = false;
      this.delayToTime = moment(date).format("YYYY-MM-DD");
    },
    changeTimeTypeCheck(v) {
      this.popActiveTimeType = v;
    },
    changeUseCheck(v) {
      this.popActiveUse = v;
    },
    changeDlCheck(v) {
      this.popActiveDielectric = v;
    },
    changeCheck(v) {
      this.popActiveTypeCrrent = v;
    },
    changeTickCheck(v) {
      this.popActiveTicketType = v;
    },

    // 检验
    validator(v) {
      return /^(\d+)(\.\d{1,2})?$/.test(v.toString()); //只能输入两个小数
    },
    inputChange(item) {
     // console.log("输入了" + JSON.stringify(item));
      this.changeCar(item);
      //看看在不在购物车；里。在的话需要修一下购物车里的item价格
    },
    //一些操作需要更改购物车里信息的
    changeCar(item) {
      const newList = [...this.$store.state.sendTickCarList];
      const index = newList.findIndex((v) => v.id == item.id);
      if (index >= 0) {
        newList[index] = { ...item };
        // 更新 Vuex 数据
        this.$store.commit("setTickCarList", newList);
      }
    },
    submitSelect() {
      this.$router.back();
    },
    goEditTick(id) {
      this.$router.push({
        path: "/tickRequest",
        query: { id: id },
      });
    },
    changeBtb(cur) {
      this.btnCur = cur;
      //切换扫券品还是选券时候清空购物车
      this.$store.commit("clearTickCartList");
    },
    onSearch(value) {},
    onClickLeft() {
      this.$router.back();
    },
    stepChange(value, detail) {
      // console.log(
      //   "点击了计步器··" + value + "···id是····" + JSON.stringify(detail.name)
      // );
      // console.log("数组··" + JSON.stringify(this.rightList));

      // 获取原购物车列表数据
      const newList = [...this.$store.state.sendTickCarList];
      // 根据id查找到就返回索引，查找不到返回 -1
      const index = newList.findIndex((v) => v.id == detail.name);
      if (index >= 0) {
        //当前购物车里有她
        if (value == 0) {
          //先看现在选得数量是0还是非0，0得话从购物车删除

          newList.splice(index, 1);
        } else {
          // 已存在商品，累加商品数量
          newList[index].num = value;
        }
      } else {
        //购物车里没有
        const selectItemObj = this.rightList.find((v) => v.id == detail.name);

        newList.push({
          ...selectItemObj,
          //   id: selectItemObj.id,
          // title: selectItemObj.title,
          // num: selectItemObj.num,
          // price: selectItemObj.price,
        });
        //console.log(selectItemObj);
      }

      // 更新 Vuex 数据
      this.$store.commit("setTickCarList", newList);
    },
  },
  computed: {
    ...mapGetters(["selectTickCount"]),
  },
  created() {
    //console.log("选券created");
  },
  destroyed() {
    //console.log("选券destroyed");
  },
  mounted() {
    this.selectType = this.$route.query.modeNo;
    this.optionCarIndex= this.$route.query.optionCarIndex
    this.getAllCategoryList();
     
  },
};
</script>
<style lang="less" scoped>
.select-outer {
  .popup-sku-con-outer {
    padding: 1rem;
    .pop-title-con {
      margin: 1rem 0;
      font-size: #333;
      font-size: 1.4rem;
    }
  }
  .delay-outer {
    padding: 2rem;
    .pop-btns {
      margin-top: 10rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      /deep/ .van-button {
        height: 4rem;
        line-height: 4rem;
      }
      .count {
        display: flex;
        align-items: center;
      }
      .num {
        font-size: 2.4rem;
        color: #006c50;
        padding: 0 1rem;
      }
    }
  }
  .delay-item-outer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    /deep/ .el-checkbox__label {
      display: none;
    }
    /deep/ .el-checkbox__inner {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-left: 1rem;
    }
  }
  .pop-date-line-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    .title {
      font-size: #333;
      font-size: 1.4rem;
    }
    .line-right {
      flex: 1;
      justify-content: space-between;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      span {
        font-size: 1.4rem;
        height: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }
  .input-outer {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    .input-pop-title {
      font-size: #333;
      font-size: 1.4rem;
    }
    .edit-input {
      flex: 1;
      display: flex;
      align-items: center;
      /deep/ .el-input {
        font-size: 1.4rem;
        margin-left: 0.5rem;
        width: 90%;
        border: none;
        border-bottom: 1px solid #ccc;
      }
      /deep/ .el-input__inner {
        height: 3rem;
        border: none;
      }
      /deep/ .el-input__suffix {
        height: 3rem;
        line-height: 3rem;
        display: flex;
        align-items: center;
      }
      /deep/ .el-textarea {
        font-size: 1.3rem;
      }
    }
  }
  .pop-check-outer {
    display: flex;
    flex-wrap: wrap;

    .check-item {
      color: #b6b6b6;
      margin-bottom: 1.5rem;
      border: 0.1rem solid #b6b6b6;
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      border-radius: 0.6rem;
      font-size: 1.3rem;
    }
    .activity {
      color: #006c50;
      border: 0.1rem solid #eef5f3;
      background-color: #eef5f3;
    }
  }
  .pop-btns {
    display: flex;
    justify-content: space-around;
    margin: 2rem 0 1rem;
    /deep/ .van-button--default {
      color: #006c50;
      width: 40%;
      background-color: #fff;
      border: 1px solid #006c50;
      font-size: 1.5rem;
    }
    /deep/ .van-button--primary {
      color: #fff;
      width: 40%;
      background-color: #006c50;
      border: 1px solid #006c50;
      font-size: 1.5rem;
    }
  }
  background-color: #f8f8f8;
  /deep/ .van-search {
    padding: 0;

    background-color: #f8f8f8;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  /deep/ .van-field__control {
    font-size: 1.3rem;
  }
  /deep/ .van-search__content {
    background-color: #eef5f3;
    border-radius: 1rem;
  }

  /deep/ .van-sidebar-item--select::before {
    background-color: #006c50;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #333;
  }
  /deep/ .van-sidebar-item__text {
    font-size: 1.3rem;
  }
  /deep/ .van-nav-bar__text {
    color: #333;
  }
  .filter-top {
    padding: 0 1rem;
    /deep/.van-icon {
      font-weight: 600;
      color: #006c50;
    }
    /deep/ .el-input__inner {
      border: none !important;
      width: 7rem;
      padding: 0.3rem;
      height: 3rem !important;
      background-color: #eef5f3;
      line-height: 3rem;
      font-size: 1.3rem;
      color: #006c50;
    }
    /deep/ .el-input__icon {
      width: 1.3rem;
    }
    /deep/ .el-input__icon {
      height: 3rem !important;
      line-height: 3rem;
      color: #006c50;
    }
    .top-selec {
      /deep/ .el-input {
        border: none !important;
      }
    }
    .top-select-drop {
      margin-left: 3rem;
      display: flex;

      align-items: center;
      /deep/ .van-dropdown-menu__bar {
        background-color: #f8f8f8 !important;
        box-shadow: none;
        height: 3.5rem;
      }
      /deep/ .van-dropdown-menu__title {
        font-size: 1.3rem;
      }
    }
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    .search-block {
      flex: 1;
    }
    .height-search {
      height: 3rem;
      line-height: 3rem;
      font-size: 1.3rem;
      color: #006c50;
      margin-left: 1rem;
    }
    .type-btns {
      width: 13rem;
      height: 3rem;
      line-height: 3rem;
      opacity: 1;
      border-radius: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 2px 10px #ccc;
      color: #333333;
      font-size: 1.3rem;
      .btn-comm {
        display: inline-block;
        width: 50%;
        text-align: center;
        color: #333333;
      }
      .cur-btn {
        color: #fff;
        background-color: #006c50;
        border-radius: 1.5rem;
      }
    }
    .top-select {
      margin-right: 1rem;
    }
    .more-search {
      color: #006c50;
    }
  }
  .con-outer {
    padding: 0 1rem;
    flex: 1;
    height: 68vh;

    overflow-y: scroll;
    margin-bottom: 5rem;
    margin-top: 1rem;
    .con-item {
      position: relative;
      padding: 2rem 0.8rem 1rem;

      background-color: #fff;
      .type {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0.6rem;
        color: #006c50;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.3rem;
        padding: 0 0.8rem;
        background: #eef5f3;
      }
      .item-title {
        color: #333;
        font-size: 1.4rem;
        word-wrap: break-word;
        white-space: normal;
        padding-bottom: 0.7rem;
        font-weight: 600;
      }
      .item-line {
        display: flex;
        justify-content: space-between;
        font-size: 1.3rem;
        color: #666666;
        align-items: center;
      }
      .item-line-con {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
      }
      .item-line-between {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item-line-clum {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
        display: flex;
        .one {
          flex: 3;
        }
        .two {
          flex: 3;

          padding-left: 1rem;
        }
        .three {
          flex: 1;
          display: flex;
          justify-content: right;
          .circle {
            width: 1.6rem;
            height: 1.6rem;
            line-height: 1.6rem;
            border-radius: 50%;
            text-align: center;
            background-color: #006c50;
            color: #fff;
            font-weight: 600;
          }
        }
      }
      .t-and-txt {
        display: flex;
        align-items: center;
        .color-t {
          width: 7rem;
          color: #999;
        }
      }

      .btween {
        display: flex;
        justify-content: space-between;
      }
      .item-line-option {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          font-size: 1.3rem;

          width: 12rem;
          border: none;
          border-bottom: 0.2rem solid #ccc;
        }
        /deep/ .el-input__inner {
          height: 3rem;
          border: none;
        }
        /deep/ .el-input__suffix {
          height: 3rem;
          line-height: 3rem;
          display: flex;
          align-items: center;
        }
        // /deep/ .el-input__suffix{
        //   height: 2rem;
        //    border: none;
        //    line-height: 2rem;

        // }
        /deep/ .van-field {
          padding: 0;
          width: 10rem;
          font-size: 1.3rem;
        }
        .price {
          width: 6rem;
        }
      }
    }
  }
  .submit-btn-outer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    .submit-nums {
      font-size: 1.5rem;
    }
    .num {
      font-weight: 600;
      padding: 0 0.8rem;
      color: #006c50;
    }
    /deep/ .van-button {
      font-size: 1.5rem;
      padding: 0.1rem 2rem;
      height: 3rem;
      line-height: 3rem;
    }
  }
}
</style>
